<template>
  <div class="purchase-method-detail-base-info">
    <div class="detail-info info-block" v-for="item in purchaseBatchDetailList" :key="item.id">
      <div style="width: 100%">
        关联采购明细：{{item.code}}
      </div>
<div class="info-item">
      <div class="label">申报部门：</div>
      <div class="value">{{item.orgName}}</div>
    </div>
    <div class="info-item">
      <div class="label">申请人：</div>
      <div class="value">{{item.workerName}}</div>
    </div>
    <div class="info-item">
      <div class="label">项目名称：</div>
      <div class="value">{{item.goodsName}}</div>
    </div>
    <div class="info-item">
      <div class="label">可选品牌及规格型号：</div>
      <div class="value">{{item.typeDetail}}</div>
    </div>
    <div class="info-item">
      <div class="label">单价：</div>
      <div class="value">{{item.price + '元'}}</div>
    </div>
    <div class="info-item">
      <div class="label">数量：</div>
      <div class="value">{{item.num}}</div>
    </div>
    <div class="info-item">
      <div class="label">总价：</div>
      <div class="value">{{item.total + '元'}}</div>
    </div>
    <div class="info-item">
      <div class="label">申请理由：</div>
      <div class="value">{{item.reason}}</div>
    </div>
    </div>
    <div class="detail-info info-block" v-if="purchaseBatchDetailList.length === 0">
      <div class="label">暂无关联采购明细</div>
    </div>
  </div>
</template>

<script>
export default {
  props: {
    purchaseBatchDetailList: { type: Array, defualt: () => [] }
  }
}
</script>

<style lang="less" scoped>
.purchase-method-detail-base-info {
  color: #515a6e;
  .info-block {
    display: flex;
    flex-wrap: wrap;
    align-content: flex-start;
  }
  .detail-info {
    margin-bottom: 10px;
    padding-bottom: 10px;
    border-bottom: 1px solid #e8eaec;
  }
  .info-item {
    display: flex;
    margin-right: 50px;
    .label {
      margin-right: 0px;
    }
  }
}
</style>